.temp__badge {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
  align-items: center;
}
.temp__badge-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}
@media (max-width: 500px) {
  .temp__badge {
    flex-direction: column;
    margin-top: 55px;
    gap: 35px;
  }
  .temp__badge img {
    width: 64.5vw;
  }
}

.footer {
  background-color: #111111;
  width: 100%;
  /* height: 10.4vw; */
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;
  /* justify-content: space-between; */
  gap: 40px;
}
.mobile-v {
  display: none;
}
.desktop-v {
  display: block;
}
.footer__socials {
  display: flex;
  gap: 20px;
  /* width: 11vw; */
}
.footer__copy-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}
.footer__copy {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  /* width: 42.2vw; */
}
.footer__copy-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #757575;
}
.footer__copy-links {
  transition: color 0.4s ease-in-out;
}
.footer__copy-links:hover {
  color: #fff;
}

.footer-m {
  display: none;
}

@media (max-width: 500px) {
  .footer {
    height: auto;
    display: block;
  }

  .footer-m {
    color: #fff;
    display: inline;
    display: flex;
    flex-direction: column;
  }
  .footer__socials {
    width: 120px;
    margin-top: 35px;
    margin-bottom: 26px;
  }

  .footer__socials img {
    width: 30px;
  }
  .mobile-v {
    display: block;
    max-width: 250px;
  }
  .desktop-v {
    display: none;
  }
  .footer__copy-container {
    align-items: flex-start;
  }

  .footer__copy {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
