.team {
  width: 100%;
}

.team__list {
  display: none;
  justify-content: space-between;
  margin-bottom: 38px;
}

.team__member {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  color: #111111;
  margin: 0;
}
.team__member img {
  width: 280px;
  height: 280px;
}

.team__member-title {
  margin: 20px 0 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-align: center;
}
.team__member-role {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #757575;
}

.btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 38px;
}
/* .team__member_avatar {
  width: 250px;
  height: 250px;
} */

/* .link_hover {
  width: 19.44vw;
  height: 19.44vw;
  background-image: url(../../assets/link_hover.svg);
  background-size: cover;
  background-position: center;
  z-index: 10;
  opacity: 0;
  transition: 0.3s;
}
.link_hover:hover {
  opacity: 1;
} */
@media (max-width: 1025px) {
  .team__member-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
  }
}
@media (max-width: 500px) {
  .link_hover:hover {
    opacity: 0;
  }
  .swiper__team {
    display: none;
  }
  .team__list {
    flex-wrap: wrap;
    display: flex;
  }
  .team__member_avatar {
    width: 168px;
    height: 168px;
  }
  .team__member img {
    width: 168px;
    height: 168px;
  }
  /* .team__member-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
  } */
  .team__member-role {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  }
}

@media (max-width: 380px) {
  .team__member_avatar {
    width: 150px;
    height: 150px;
  }
  .team__member img {
    width: 150px;
    height: 150px;
  }
}
