.cta {
  width: 100%;
  /* min-height: 541px; */
  /* height: 31.32vw; */
}

.cta__content {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

.cta__container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 70px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
}

.cta__title {
  font-weight: 700;
  font-size: 46px;
  line-height: 90%;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.cta__subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin: 34px 0;
  max-width: 370px;
}

@media (max-width: 1025px) {
  .cta__container {
    padding: 40px 20px;
  }
  .cta__title {
    font-size: 24px;
  }
  .cta__subtitle {
    font-size: 14px;
    margin: 26px 0;
  }
}
@media (max-width: 500px) {
  .cta {
    /* height: 124.5vw; */
  }
  .cta__container {
    background-image: url(../../assets/ctam.png) !important;
    height: 124.5vw;
    padding: 0 0 8.53vw 5.33vw;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .cta__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    max-width: 250px;
    margin: 26px 0 21px 0;
  }
  .cta__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;
    max-width: 250px;
  }
}
