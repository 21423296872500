.map-container {
  position: relative;
  width: 100%;
}

.map-container_m {
  display: none;
}
.yandexMap {
  width: 100%;
  height: 48.61vw;
}

.filterBar {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 30px;
  margin: 34px 0 19px 0;
}

.filterBar__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  overflow: scroll;
}
.filterBar__tabs::-webkit-scrollbar {
  display: none;
}

.filterBar__tabs-item {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #111111;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background-color: inherit;
}

.filterBar__tabs-item:hover {
  background-color: #b2b2b2;
}
.searchBar {
  padding: 10px 20px;
  gap: 10px;
  border-radius: 100px;
  width: 117px;
  height: 41px;
  border: none;
  background-color: #f5f5f5;
  position: relative;
}
.search-icon {
  position: absolute;
  right: 30px;
  z-index: 99999;
  top: 19.5px;
  pointer-events: none;
}
.searchBar:focus {
  border: 1px solid #111111;
  z-index: 999;
  width: 40%;
}

.active {
  background: #111111;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.5s;
}

.info-container {
  position: relative;
  display: block;
}

.info {
  width: 380px;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  position: absolute;
  overflow: scroll;
  padding-right: 10px;
  top: 0;
}

.info__name {
  padding: 20px 0 0 0;
}
.info__name-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #757575;
}
.info__name-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
  padding: 10px 0;
}
.info__name-address {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #757575;
}

.info__about {
  margin-top: 20px;
}
.info__about-title {
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}
.info__about-descr {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #111111;
  margin-top: 10px;
  white-space: pre-wrap;
}

.info__team {
  margin-top: 20px;
}

.info__team-title {
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
  margin-bottom: 15px;
}

.info__team-list__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.info__team-list__item img {
  width: 60px;
  border-radius: 200px;
}

.member__descr {
  margin-left: 20px;
}
.member__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}
.member__role {
  font-size: 16px;
  line-height: 130%;
  color: #757575;
  margin-top: 5px;
}

@media (max-width: 1025px) {
  .map-container {
    padding-bottom: 0;
  }
  .none_class {
    display: none;
  }
  .map-container {
    overflow: hidden;
  }

  .yandexMap {
    width: 100%;
    height: 80vh;
  }
  .info {
    z-index: 1;
    height: 100vh;

    transition: 1s ease-in-out;
  }
  .info_up {
    transform: translateY(0);
  }
  .info_down {
    transform: translateY(71%);
  }

  .map-container_m {
    display: block;
    width: 100%;
  }
  .map-container_m-bg {
    background: url(../../assets/map_tablet.png) center / cover no-repeat;
    height: 340px;
    padding: 40px 0 0 20px;
  }
  .map-container_m__title {
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
    color: #fff;
  }
}
@media (max-width: 768px) {
  .map-container_m-bg {
    background: url(../../assets/map-m.png) center / cover no-repeat;
    height: 88.5vw;
    padding: 10.66vw 0 0 5.33vw;
  }
  .slider__map {
    width: 95.73vw;
  }

  .info {
    /* display: none; */
    width: 95.73vw;
    z-index: 1;
    /* top: 75%; */
  }

  .info_up {
    transform: translateY(-10%);
  }
  .info_down {
    transform: translateY(60%);
  }
  .info__gallery-list {
    gap: 10px;
    overflow: scroll;
  }
}

@media (max-width: 360px) {
  .info_up {
    transform: translateY(-15%);
  }
  .info_down {
    transform: translateY(38%);
  }
}
