.carousel-navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  align-items: center;
}

.carousel-navigation__title {
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}

.carousel-navigation__controls {
  /* width: 14vw; */
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.carousel-navigation__controls button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: none;
  height: 40px;
  width: 40px;
}

.carousel-navigation__controls a {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #111111;
  width: 105px;
}
.backMap,
.back-map-link {
  display: none;
}

@media (max-width: 1025px) {
  .carousel-navigation__controls {
    width: auto;
  }
  .backMap,
  .back-map-link {
    display: block;
  }
  .prev,
  .next {
    display: none;
  }
}
