.gallery {
  margin-top: 20px;
}
.galleryMain {
  border-radius: 8px;
}
.galleryMain img {
  width: 380px;
  border-radius: 8px;
}
.galleryList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 380px;
}
.galleryList__item {
  opacity: 0.4;
  cursor: pointer;
  border-radius: 4px;
}
.galleryList__item img {
  width: 60px;
  border-radius: 4px;
}
.active {
  opacity: 1;
}

@media (max-width: 768px) {
  .galleryList {
    overflow: scroll;
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */
  }
  .galleryList::-webkit-scrollbar {
    width: 0;
    opacity: 0;
    display: none;
  }
  .galleryMain img {
    width: 91.46vw;
  }
  .galleryList {
    flex-wrap: nowrap;
  }
}
