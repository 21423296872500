.invite-link__container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.invite-link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #111111;
  display: inline-block;
  align-self: center;
}
