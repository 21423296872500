.internalNavbar {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
}
.internalNavbar__backArrow {
  display: none;
}

.internalNavbar__burger {
  display: none;
}
.internalNavbar-title {
  display: none;
}
.internalNavbar__nav-list {
  display: flex;
  /* justify-content: space-between; */
  /* width: 55.15vw; */
  gap: 2.77vw;
}
.internalNavbar__nav-list__item {
  padding: 20px 0;
}
.internalNavbar__mobileNav {
  display: none;
}

.active-item {
  border-bottom: 3px solid #111111;
}

@media (min-width: 1026px) {
  .main-logo {
    position: absolute;
    top: 18px;
    left: calc((100% - 1180px) / 2);
  }
}

@media (max-width: 1025px) {
  .internalNavbar {
    justify-content: space-between;
    padding: 10px 17px;
    align-items: center;
  }
  .internalNavbar__mobileNav {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: absolute;
    padding: 21px 17px 0 16px;
    top: 0;
    left: 0;
    z-index: 998;
  }
  .internalNavbar__nav-list__item {
    padding: 7.5px 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    color: #111111;
  }
  .internalNavbar__backArrow {
    display: block;
  }
  .internalNavbar-title {
    display: block;
  }
  .internalNavbar__burger {
    display: block;
    width: 40px;
    height: 40px;
    background: url(../../assets/burger.svg) center / 18px 18px no-repeat;
  }

  .internalNavbar__mobileNav-links {
    display: flex;
    flex-direction: column;
    padding-top: 59px;
  }

  .internalNavbar__close {
    display: block;
    width: 18px;
    height: 18px;
    background: url(../../assets/close-button.svg) center / contain no-repeat;
    z-index: 999;
    position: absolute;
    top: 21px;
    right: 17px;
  }
  .internalNavbar__nav-list {
    display: none;
  }
}
