.partners {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: #111111;
  position: relative;
  display: flex;
  flex-direction: column;
}
.partners__list::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
.partner {
  position: relative;
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.partner_hover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../../assets/link_hover.svg) center / contain no-repeat;
  z-index: 10;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
}

.partner_hover:hover {
  opacity: 1;
}
.partner__name {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  color: #757575;
  max-width: 100px;
  white-space: pre-wrap;
}

.partners__list {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 34px 0 38px;
  flex-wrap: wrap;
}
/* .partners__list::-webkit-scrollbar {
  display: none;
} */

@media (max-width: 1025px) {
  .partners__list {
    padding-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .partner__name {
    display: none;
  }
  .partner_hover:hover {
    opacity: 0;
  }
  .partners__list {
    margin: 24px 0 52px;
    padding-bottom: 20px;
    gap: 0;
    justify-content: space-between;
  }

  .partner img {
    width: 80px;
  }
  .partner:last-child {
    margin-top: 6.5px;
  }
}
