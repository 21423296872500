.profile__wrap {
  position: relative;
  display: grid;
}

.profile__container {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: flex-start;
  grid-column: span 4;
}

.avatar__container {
}
.bio__container {
  padding-top: 20px;
}
.bio__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #111111;
  margin-bottom: 10px;
}
.bio {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #111111;
  max-width: 360px;
  white-space: pre-wra;
}

@media (max-width: 768px) {
  .profile__container {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .bio__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12.4vw;
  }
  .bio__title {
    width: 100%;
  }
}
