.link-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  /* width: 123px;
  height: 41px; */
}

@media (max-width: 500px) {
  .link-button {
    padding: 8px 20px;
  }
}

.link-button_center {
  margin: 0 auto;
}

.link-button_white {
  background-color: #ffffff;
  color: #111111;
}
.link-button_black {
  background-color: #111111;
  color: #ffffff;
}
