@media (max-width: 768px) {
  .info__mobile-bar {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info__mobile-bar__control {
    width: 40px;
    height: 5px;
    background: #757575;
    border-radius: 100px;
  }
}
