.event-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}
.hack-event {
  padding: 1.38vw 9vw;
}
.hack-event-m {
  padding: 0;
}
.hack-event-gallery {
  padding: 0;
}

@media (max-width: 768px) {
  .hack-event {
    padding: 35px 0 0 0;
  }
  .hack-event-m {
    padding: 0 4.27vw;
  }
  .hack-event-gallery {
    padding: 0 0 0 4.27vw;
  }
}

.event__status-container {
  display: flex;
  gap: 10px;
}
.event-text_secondary {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #757575;
}
.event-text_primary {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}
.event__status {
}
.event__content {
  display: flex;
  /* gap: 20px; */
  justify-content: space-between;
}
.event-gal-m {
  display: none;
}
.event-gal-d {
  display: block;
}
@media (max-width: 768px) {
  .event__content {
    flex-direction: column;
  }
  .event-gal-m {
    display: block;
  }
  .event-gal-d {
    display: none;
  }
}
.event__info {
  width: 360px;
}
.event__info-title__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.event__info-title {
  margin-bottom: 10px;
}
.event__info-about {
  margin-bottom: 35px;
}
.event__place-title {
  font-weight: 500;
  margin-bottom: 5px;
}
.event__schedule-container {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
@media (max-width: 768px) {
  .event__schedule-container {
    padding-bottom: 140px;
  }
  .event-text_primary {
    font-size: 20px;
  }
  .event-text_secondary {
    font-size: 14px;
  }
}
.event__schedule-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 370px;
}
.event__schedule-item__date {
}
.event__schedule-item__title {
  white-space: pre-wrap;
}
.event__gallery-container {
}
