.close-btn__container {
  width: 100%;
  box-shadow: 0px 4px 8px rgba(123, 129, 149, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.close-btn {
  background-color: inherit;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  color: #111111;
}

.form__container {
  display: flex;
  gap: 32px;
  /* width: max(29.16vw, 530px); */
  width: min(100%, 560px);
  padding: 3.645vw 0;
  flex-direction: column;
}
@media (max-width: 768px) {
  .form__container {
    padding: 3.645vw 140px;
  }
}

.form__container h2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.form-item__label {
  position: absolute;
  pointer-events: none;
  left: 14px;
  right: 14px;
  top: 14px;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #868690;
}
.form-item__area ~ .form-item__label {
  left: 10px;
  right: 10px;
}
.form-item__input,
.form-item__area {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}
.form-item__input {
  padding: 14px 0px 11px 14px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.form-item__area {
  padding: 14px 10px 10px;
  border: 1px solid #e0e0e0;
}

.form-item__input:focus + .form-item__label,
.form-item__input:not(:placeholder-shown) + .form-item__label,
.form-item__area:focus + .form-item__label {
  top: 5px;
  font-size: 11px;
  line-height: 118%;
  letter-spacing: 0.02em;
}

.form p {
  margin: max(1.25vw, 4px) 0 max(3.645vw, 48px);
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0px 11px;
  height: 60px;
  color: #ffffff;
  background: #000000;
  border-radius: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
}

::-webkit-input-placeholder {
  opacity: 0;
}

@media (max-width: 768px) {
  .form__container {
    padding: 35px 15px 130px;
  }
}

.popup {
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  background: rgba(17, 17, 17, 0.8);
  backdrop-filter: blur(10px);
}

.popup-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
  position: relative;
  width: min(520px, 80%);
  height: 272px;

  background: #ffffff;
  border-radius: 6px;
  background: #fff;
}
.popup-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1;
  text-align: center;
  letter-spacing: -0.02em;
  color: #111111;
}
.popup-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  color: #757575;
  margin-top: 10px;
  margin-bottom: 35px;
}

@media (max-width: 500px) {
  .popup-content {
    padding: 20px;
  }
}
