.creation {
  width: 100%;
  min-height: 80vh;
  position: relative;
}

.creation__container {
  width: 100%;
  height: 552px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10.45vw 70px 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url(../../assets/creation-bg.png);
}

.creation__content {
  max-width: 600px;
}
.creation__title {
  color: #111;
  font-family: Roboto;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 41.4px */
  letter-spacing: -0.92px;
}

.creation__subtitle {
  color: #111;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  max-width: 350px;
}
.subtitle_desk {
  margin: 34px 0;
}
.subtitle__mobile {
  display: none;
  margin: 0;
}
.subtitle__mobile_bottom {
  margin-bottom: 34px;
}
.image_m {
  display: none;
}

@media (max-width: 1025px) {
  .creation {
    height: auto;
    padding: 0 0 12.93vw 0;
  }
  .creation__container {
    background-image: none;
    flex-direction: column;
    padding: 10.66vw 4.27vw 0;
    height: auto;
    max-width: none;
    margin: 0;
  }
  .creation__title {
    margin: 23px 0 28px 0;
    font-weight: 700;
    font-size: 38px;
    line-height: 115%;
    letter-spacing: -0.02em;
    color: #111111;
  }
  .creation__subtitle {
    display: none;
  }
  .subtitle__mobile {
    display: block;
  }
  .image_m {
    /* position: absolute; */
    left: 0;
    width: 100vw;
    display: block;
    margin-top: 35px;
  }
}

@media (max-width: 500px) {
  .creation__title {
    margin: 23px 0 28px 0;
    font-weight: 700;
    font-size: 38px;
    line-height: 115%;
    letter-spacing: -0.02em;
    color: #111111;
  }
  .creation__container {
    max-width: 343px;
  }
}

@media (max-width: 700px) {
  .creation__link {
    display: none;
  }
}

@media (min-width: 700px) {
  .creation__link_m {
    display: none;
  }
}
