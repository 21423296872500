.App {
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  gap: 2vw;
  /* overflow: hidden; */
  transition: 0.4s;
}

.section__padding {
  padding: 1.38vw 9vw;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

@media (max-width: 1025px) {
  .section__padding {
    padding: 2.92vw 4.27vw;
  }
}

@media (max-width: 500px) {
  .section__padding {
    padding: 12.93vw 4.27vw;
  }
}
