.news {
  width: 100%;
}
.news__list {
  height: 700px;
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
}
.news-full {
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  padding: 0 0 70px 70px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  opacity: 0;
  border-radius: 8px;
  background: rgba(17, 17, 17, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  font-family: 'Roboto', sans-serif;
  transition: 0.3s;
}
.news-full:hover {
  opacity: 1;
}

.news__list-item {
  padding: 0 0 70px 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  position: relative;
  background: center / cover no-repeat linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 8px;
}
.news__list-item__date {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #ffffff;
  opacity: 0.7;
  margin-bottom: 10px;
}
.item-1 {
  height: 100%;
  width: 40.27vw;
}
.lazy-container {
  height: 100%;
  display: flex;
  /* width: 580px; */
  width: 40.27vw;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 20px;
}
.item-2,
.item-3 {
  width: 100%;
  height: 50%;
}

.news__list-item__content {
  max-width: 455px;
}
.news__list-item__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
}
.news__list-item__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 5px 0 44px;
}

.link-btn-news {
  display: inline-block;
  background-color: #ffffff;
  color: #111111;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
}
.news_m {
  display: none;
}
@media (max-width: 1025px) {
  .news__list-item,
  .news-full {
    padding: 40px 20px;
  }
  .news_m {
    display: flex;
    overflow: scroll;
    margin-top: 24px;
    gap: 20px;
    scrollbar-width: none;
  }
  .news_m::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  .news__list {
    display: none;
  }
  .news__list-item__description {
    /* font-weight: 400;
    font-size: 16px;
    line-height: 130%; */
    margin: 5px 0 0px;
  }
}
@media (max-width: 500px) {
  .news__list-item__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
  }
  /* .news__list {
    display: none;
  } */
}
