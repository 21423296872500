.externalNavbar {
  background-color: #f5f5f5;
}
.externalNavbar__content {
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.externalNavbar p,
.externalNavbar a {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #111111;
}

.externalNavbar__nav-list {
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.externalNavbar__nav-list__separator {
  width: 0.5px;
  background-color: #757575;
  height: 10px;
  margin-bottom: 2px;
}

@media (max-width: 1025px) {
  .externalNavbar {
    display: none;
  }
}
