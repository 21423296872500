.creators__container {
  /* display: flex;
  gap: 20px;
  flex-wrap: wrap; */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(168px, 280px));
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  padding-bottom: 105px;
  max-width: 1180px;
  margin: 0 auto;
}
.member__grid {
  display: grid;
  grid-auto-flow: row;
}

@media (max-width: 768px) {
  .creators__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
