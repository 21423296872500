.events {
  width: 100%;
}

.events__list {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.events__list-item {
  max-width: 380px;
  font-family: 'Roboto', sans-serif;
}

.events__list-item img {
  width: 26.38vw;
}

.events__list-item__type {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #757575;
}

.events__list-item__title {
  margin: 10px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #111111;
}

.events__list-item__date {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #757575;
}

.prevE,
.nextE {
  border-radius: 25px;
}

@media (max-width: 1025px) {
  .events__list {
    display: none;
  }
  .swiper_events {
    display: none;
  }
}
