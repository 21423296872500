.mobile-slider {
  min-width: 285px;
  max-width: 285px;
  /* padding-bottom: 15px; */
}
.mobile-slider img {
  margin-bottom: 20px;
}
.slider-margin {
  margin: 0 20px;
}
.mobile-slider__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #000000;
}
.mobile-slider__subtitle {
  max-width: 280px;
}
